import * as React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import 'typeface-merienda-one';
import SEO from '../seo';

export type Props = {
  data: {
    site: {
      siteMetadata: {
        description: string;
      };
    };
    image: {
      fluid: {
        srcSet: string;
        originalName: string;
      };
      fields: {
        name: string;
      };
      original: {
        width: number;
        height: number;
        src: string;
      };
    };
  };
};

const ShareablePage = ({ data }: Props) => {
  return (
    <>
      <SEO
        title="Shareables"
        description={data.site.siteMetadata.description}
        image={data.image.original}
      />
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
        `}
      >
        <img
          srcSet={data.image.fluid.srcSet}
          src={data.image.original.src}
          alt={data.image.fields.name}
          css={css`
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          `}
        />
      </div>
    </>
  );
};

export default ShareablePage;

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        description
      }
    }
    image: imageSharp(id: { eq: $id }) {
      id
      fields {
        name
      }
      fluid(jpegProgressive: true, maxWidth: 500) {
        srcSet
      }
      original {
        width
        height
        src
      }
    }
  }
`;
