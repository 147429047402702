import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export type Props = {
  description?: string;
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
  image?: { src: string; width: number; height: number };
  title: string;
  article?: {
    tags?: Array<string>;
    publishedTime?: string;
  };
};

function SEO({
  description = '',
  lang = 'en',
  meta = [],
  title,
  image,
  article,
}: Props) {
  const { site } = useStaticQuery<{
    site: {
      siteMetadata: {
        title: string;
        description: string;
        author: string;
        siteUrl: string;
        twitter: string;
      };
    };
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitter
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: article ? 'article' : 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.twitter,
        },
        {
          name: 'twitter:domain',
          content: site.siteMetadata.siteUrl,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.twitter,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...(image
          ? [
              {
                property: 'og:image',
                content: `${site.siteMetadata.siteUrl}${image.src}`,
              },
              { property: 'og:image:width', content: `${image.width}` },
              { property: 'og:image:height', content: `${image.height}` },
              {
                name: 'twitter:image',
                content: `${site.siteMetadata.siteUrl}${image.src}`,
              },
            ]
          : []),
        ...(article
          ? [
              ...(article.publishedTime
                ? [
                    {
                      property: 'og:article:published_time',
                      content: article.publishedTime,
                    },
                  ]
                : []),
              ...(article.tags
                ? article.tags.map(tag => ({
                    property: 'og:article:tag',
                    content: tag,
                  }))
                : []),
            ]
          : []),
      ].concat(meta)}
    />
  );
}

export default SEO;
